import { AbstractControl } from "@angular/forms";

export function cpfValidator(control: AbstractControl): { [key: string]: any } | null {
  const cpf = control.value;

  if (!cpf) {
    return null;
  }

  if (!validarCPF(cpf)) {
    return { cpfValidator: true };
  }
  return null;
}

export function cnpjValidator(control: AbstractControl): { [key: string]: any } | null {
  const cnpj = control.value;

  if (!cnpj) {
    return null;
  }

  if (!validarCNPJ(cnpj)) {
    return { cnpjValidator: true };
  }
  return null;
}

function validarCPF(cpf: string): boolean {
  // Remove caracteres não numéricos
  const cpfLimpo = cpf.replace(/[^\d]/g, '');

  // Verifica se o CPF tem 11 dígitos
  if (cpfLimpo.length !== 11) {
    return false;
  }

  // Verifica CPFs com números iguais
  if (/^(\d)\1+$/.test(cpfLimpo)) {
    return false;
  }

  // Validação do primeiro dígito verificador
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpfLimpo.charAt(i)) * (10 - i);
  }
  let digito = (soma * 10) % 11;
  if (digito === 10 || digito === 11) digito = 0;
  if (digito !== parseInt(cpfLimpo.charAt(9))) return false;

  // Validação do segundo dígito verificador
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpfLimpo.charAt(i)) * (11 - i);
  }
  digito = (soma * 10) % 11;
  if (digito === 10 || digito === 11) digito = 0;
  if (digito !== parseInt(cpfLimpo.charAt(10))) return false;

  return true;
}

function validarCNPJ(cnpj: string): boolean {
  // Remove caracteres não numéricos
  const cnpjLimpo = cnpj.replace(/[^\d]/g, '');

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpjLimpo.length !== 14) {
    return false;
  }

  // Verifica CNPJs com números iguais
  if (/^(\d)\1+$/.test(cnpjLimpo)) {
    return false;
  }

  // Validação do primeiro dígito verificador
  let soma = 0;
  let peso = 2;
  for (let i = 11; i >= 0; i--) {
    soma += parseInt(cnpjLimpo.charAt(i)) * peso;
    peso = peso === 9 ? 2 : peso + 1;
  }
  let digito = (soma % 11 < 2) ? 0 : 11 - (soma % 11);
  if (digito !== parseInt(cnpjLimpo.charAt(12))) return false;

  // Validação do segundo dígito verificador
  soma = 0;
  peso = 2;
  for (let i = 12; i >= 0; i--) {
    soma += parseInt(cnpjLimpo.charAt(i)) * peso;
    peso = peso === 9 ? 2 : peso + 1;
  }
  digito = (soma % 11 < 2) ? 0 : 11 - (soma % 11);
  if (digito !== parseInt(cnpjLimpo.charAt(13))) return false;

  return true;
}
